@import "../../../../../Assets/theme/_var.scss";

.play_section {
    padding: 4rem 0 15.1rem;
    overflow-x: hidden;
    @media (max-height: 767px) or (max-width: 1439px) {
        padding: 4rem 0 10rem;
    }

    @media (max-width: 991px) {
        padding: 4rem 0 6rem;
    }

    :global {
        .container {
            max-width: 95.4rem;

            .row {
                margin: -1rem;

                >div {
                    padding: 1rem;
                }
            }
        }
    }

    .play_heading {
        margin-bottom: 5.7rem;

        @media (max-width: 991px) {
            margin-bottom: 4rem;
        }

        h2 {
            padding-bottom: 2rem;
        }
    }

    .play_card {
        text-align: center;

        >div {
            padding: 7.8rem 2rem 6.8rem;

            @media (max-width: 1439px) {
                padding: 5rem 1.6rem;
            }

            @media (max-width: 1199px) {
                padding: 4rem 1.6rem;
            }

            @media (max-width: 991px) {
                padding: 3rem 1.6rem;
            }
        }

        &_icon {
            span {
                width: 11.8rem;
                height: 11.8rem;
                border-radius: 50%;
                background-color: $basecolor4;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid transparent;
                background: linear-gradient(180deg, $basecolor4 0%, $basecolor4 100%) padding-box, linear-gradient(180deg, #BE5D46 0%, #140805 100%) border-box;

                @media (max-width: 1439px) {
                    width: 10rem;
                    height: 10rem;

                    svg {
                        max-width: 40%;
                        height: auto;
                    }
                }

                @media (max-width:1199px) {
                    width: 8rem;
                    height: 8rem;
                }

                @media (max-width: 991px) {
                    width: 7rem;
                    height: 7rem;
                }
            }
        }

        h3 {
            font-family: $secondaryfont;
            font-size: 2.2rem;
            font-weight: 400;
            line-height: 2.2rem;
            margin-top: 4.3rem;

            @media (max-width: 1439px) {
                font-size: 2rem;
                margin-top: 4rem;
            }

            @media (max-width:1199px) {
                font-size: 1.8rem;
                margin-top: 3rem;
            }

            @media (max-width: 991px) {
                font-size: 1.6rem;
                margin-top: 2rem;
            }
        }

        p {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.95rem;
            color: rgba($white, 0.8);
            margin: 2.1rem 0 1.8rem;

            @media (max-width: 991px) {
                font-size: 1.4rem;
                margin: 2rem 0 1rem;
            }
        }

        .action_btn {
            width: 21rem;
            padding: 0;
            height: 6.3rem;

            &::before {
                content: unset;
            }
        }
    }
}