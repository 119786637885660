@import "../../../Assets/theme/_var.scss";

.header {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 1049;
    border-bottom: 1px solid transparent;
    transition: 200ms ease-in-out;

    .header_inner {
        transition: 200ms ease-in-out;
        display: flex;
        // align-items: flex-start;
        align-items: center;
        justify-content: space-between;
        padding: 2.4rem 0;

        .header_logo {
            transition: 200ms ease-in-out;
            max-width: 10.7rem;
            z-index: 0;
        }

        .header_navigation {
            display: flex;
            align-items: center;
        }

        @media (max-width: 991px) {
            align-items: center;
            padding: 1.4rem 0;

            .header_logo {
                max-width: 6rem;
            }
        }

        @media (max-width: 767px) {
            .header_logo {
                max-width: 4rem;
            }
        }
    }

    &.active {
        background-color: $basecolor;
        border-bottom: 1px solid rgba($orange2, 0.3);
        box-shadow: 1rem 0 1rem 0.4rem rgba($orange2, 0.3);

        @media (min-width: 991px) {
            .header_inner {
                padding: 2rem 0;
                // align-items: center;

                .header_logo {
                    max-width: 6rem;
                }
            }

        }
    }
}