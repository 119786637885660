@import "../../../Assets/theme/_var.scss";

.button {
    height: 6.3rem;
    padding-inline: 8.5rem;
    border: none;
    outline: none;
    background-image: url(../../../Assets/Images/btnbg3.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 200ms ease-in-out;
    position: relative;
    // z-index: 1;


    // &:not([data-variant*="bordered"]) {
    //     &::before {
    //         content: "";
    //         width: 100%;
    //         height: 100%;
    //         z-index: -1;
    //         filter: blur(12px);
    //         // background-color: $orangedark;
    //         background-image: url(../../../Assets/SVG/btnbg.svg);
    //         background-size: cover;
    //         background-position: center;
    //         background-repeat: no-repeat;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%);
    //     }
    // }

    span {
        transform: translate(0.7rem, 0.5rem);
        z-index: 2;
        position: relative;
        transition: 0.3s ease-in-out;
    }

    &:hover {
        span {
            transition: 0.3s ease-in-out;
            color: $black;
        }
    }

    &:active {
        transform: scale(0.96);
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &[data-variant*="bordered"] {
        background-image: url(../../../Assets/Images/bordered-btn.png);
        background-size: cover;

        span {
            transform: translate(-0.6rem, -0.1rem);
            color: $orange3;
            @media (max-width: 767px) {
            top: -5px;
            }
        }

        &:hover {
            span {
                color: $white;
                transition: 0.3s ease-in-out;
            }
        }
    }

    &[data-variant*="secondary-btn"] {
        background-image: url(../../../Assets/Images/btnbg4.png);
    }

    &[data-variant*="secondary-bordered"] {
        background-image: url(../../../Assets/Images/bordered-btn-2.png);
    }

    @media (max-width: 1199px) {
        padding-inline: 6.5rem;
        font-size: 1.6rem;
        // height: 4.8rem;

        span {
            transform: translate(0.4rem, 0.65rem);
            @media (max-width: 767px) {
                top: -5px;
                }
        }

        &[data-variant*="bordered"] {
            span {
                transform: translateY(00rem);
            }
        }
    }

    @media (max-width: 767px) {
        font-size: 1.4rem;
    }
}

.simple_btn {
    color: $orange4;
    font-size: 1.6rem;
    transition: 200ms ease-in-out;
    font-weight: 400;
    border-radius: 1rem;
    border: 1px solid $orange4;
    background: rgba($orange4, 0.149);
    padding: 0 3.341rem;
    font-family: $poppins;
    height: 4.6rem;

    &:active {
        transform: scale(0.965);
    }

    &:hover {
        background: $orange4;
        color: $white;
    }

    &[data-variant*="bordered-orange"] {
        background-color: rgba($orangedark, 0.15);
        border: 1px solid $orangedark;
        color: $white;

        &:hover {
            background-color: $orangedark;
        }
    }
}