@import "../../../../Assets/theme/_var.scss";

.banner {
    background-image: url(../../../../Assets/Images/banner-bg-2.png);
    background-size: cover;
    // padding: 20rem 0 8rem;
    padding: 20rem 0 10rem;
    background-position: 60%;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    z-index: 1;
    // max-height: 80rem;

    @media (max-width: 1679px) {
        padding: 16rem 0 8rem;
    }

    @media (max-width: 1439px) {
        min-height: auto;
        padding: 14rem 0 8rem;
        max-height: unset;
    }

    @media (max-width: 575px) {
        padding: 9rem 0 6rem;
    }

    @media (max-width: 575px) {
        background-position: center;
    }

    [aria-label="crows"] {
        position: absolute;
        top: -18rem;
        width: 100%;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);

        svg {
            width: 100%;
        }

        @media (max-width: 1679px) {
            top: -10rem;
        }
    }

    [aria-label="pumpkin"] {
        position: absolute;
        z-index: -1;
        bottom: 13rem;
        left: 50%;
        transform: translateX(-42%);
        width: 100%;

        @media (max-width: 1679px) {
            bottom: 12rem;
            transform: translateX(-46%);
        }

        svg {
            width: 100%;
        }
    }

    &_inner {
        display: flex;

        @media (max-width: 1679px) {
            align-items: center;
        }

        @media (max-width: 991px) {
            flex-direction: column-reverse;
        }
    }

    &_text {

        h1 {
            color: $white;
            font-family: $secondaryfont;
            font-size: 6rem;
            font-weight: 400;
            line-height: 7.6rem;
            // margin-top: 8rem;
            margin-top: 15rem;
            margin-bottom: 0;

            @media (max-width: 1679px) {
                font-size: 5.4rem;
                line-height: 7rem;
                margin-top: 0;
            }

            @media (max-width: 1439px) {
                font-size: 5rem;
                line-height: 6.4rem;
            }

            @media (max-width: 1199px) {
                font-size: 4.4rem;
                line-height: 5.4rem;
            }

            @media (max-width: 991px) {
                font-size: 4rem;
                line-height: 5rem;
                text-align: center;
            }

            @media (max-width: 767px) {
                font-size: 3.2rem;
                line-height: 4.8rem;
                margin-bottom: 1rem;
            }

            @media (max-width: 575px) {
                font-size: 3rem;
                line-height: 4rem;
            }
        }

        p {
            max-width: 58.6rem;
            color: $white;
            font-size: 2rem;
            font-weight: 400;
            line-height: 3rem;
            margin: 1rem 0 5rem;

            @media (max-width: 1439px) {
                font-size: 1.8rem;
                line-height: 2.8rem;
            }

            @media (max-width: 1199px) {
                font-size: 1.6rem;
                line-height: 2.6rem;
                margin: 1rem 0 3rem;

            }

            @media (max-width: 767px) {
                font-size: 1.4rem;
                text-align: center;
            }
        }

        .coming__soon {
            position: relative;
            flex: 0 1 100%;
            max-width: 20.5rem;

            @media (max-width: 767px) {
                width: 100%;
                margin-inline: auto;
                margin-bottom: 4rem;
            }

            .coming_soon_text {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 1.6rem;
                font-family: $secondaryfont;
                width: 100%;
                text-align: center;
            }
        }

        .btn_mint {
            padding: 0;
            margin-right: 0.8rem;
            background-size: cover;
            width: 100%;

            span {
                transform: translate(-0.5rem, -0.2rem);
            }
        }

        .btn_join {
            padding: 0;
            flex: 0 1 100%;
            max-width: 20.5rem;
            margin-right: 0.8rem;
            background-size: cover;

            @media (max-width: 767px) {
                width: 100%;
                display: block;
                margin-inline: auto;
            }

            span {
                transform: translate(0.5rem, 0.6rem);
            }
        }
    }

    .banner_img {
        max-width: 80rem;
        transform: translateY(-8rem) translateX(-8rem);
        margin-left: auto;

        @media (max-width: 1679px) {
            padding-left: 4rem;
            transform: none;
            max-width: 65rem;
        }

        @media (max-width: 1199px) {
            max-width: 52rem;
        }

        @media (max-width: 991px) {
            max-width: 39rem;
            margin: 0 auto 4rem;
            padding-left: 0;
            transform: unset;
        }

        @media (max-width: 767px) {
            // transform: translateX(-4rem);
        }

        @media (max-width: 479px) {
            max-width: 30rem;
            // transform: translateX(-3rem);
        }

        // @media (max-width: 365px) {
        //     transform: unset;
        // }
    }
}