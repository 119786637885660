@import "../../../../Assets/theme/_var.scss";

.Signup_Access {
    padding: 10rem 0;
    overflow: hidden;
    position: relative;
    z-index: 1;

    // padding-bottom: 4.8rem;
    @media (max-width:991px) {
        padding: 4rem 0;
    }

    &_box {
        max-width: 134.2rem;
        width: 100%;
        margin: 0 auto;
        padding: 5rem 2rem 7rem;
        border-radius: 4rem;
        position: relative;
        border: 1px solid transparent;
        background: linear-gradient(270deg, #322220 50.6%, rgba(27, 16, 15, 0) 93.44%),
            linear-gradient(169deg, #FFFFFF00 7.82%, rgba(255, 255, 255, 0) 49.96%);

        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: inherit;
            width: calc(100% + 0.3rem);
            height: 101%;
            background: linear-gradient(169deg, rgba($white, 0.8) 7.82%, rgba(255, 255, 255, 0.0) 40.96%) border-box;
            border: inherit;
            mask: linear-gradient(to right, white, white) padding-box,
                linear-gradient(to right, white, white);
            mask-composite: exclude;
        }

        @media (max-width:575px) {
            padding: 3rem 2rem;
        }

        h2 {
            z-index: 2;

            @media (min-width: 1440px) {
                font-size: 3.4rem;
                line-height: 3.4rem;
            }
            @media (max-width: 767px) {
                padding: 0 1rem;
                font-size: 2.2rem;
            }

            &::after {
                content: unset;
            }
        }

        &_InputArea {
            z-index: 2;
            display: flex;
            position: relative;
            justify-content: center;
            width: 100%;
            max-width: 76.6rem;
            margin: auto;
            margin-top: 5rem;
            @media (max-width:767px) {
                margin-top: 1rem;

            }
            .signup_input {
                position: relative;
                justify-content: center;
                @media (max-width:767px) {
                    justify-content: center;
                }

                input {
                    padding: 1.7rem 1.7rem 1.7rem 3.8rem;
                    background: none;
                    stroke: #6C554D;
                    border-radius: 1.5rem;
                    backdrop-filter: blur(22px);
                    height: 7rem;
                    width: 100%;
                    box-shadow: 0px 4px 11px 0px rgba(255, 252, 251, 0.29) inset;
                    border: 0;
                    font-size: 1.6rem;
                    fill: rgba(45, 37,
                            33,
                            0.20);
                    font-weight: 400;
                    color: rgba($white , 1);
                    border-top-right-radius: 1.5rem !important;
                    border-bottom-right-radius: 1.5rem !important;

                    &::placeholder {
                        color: rgba($white, 0.3);
                    }

                    &:focus {
                        z-index: 1;
                    }

                    @media (max-width: 767px) {
                        height: 5rem;
                        padding: 0 3rem;
                    }
                }

                .signup_btn {
                    // position: absolute;
                    // right: -10.9rem;
                    width: 26.2rem;
                    z-index: 1;
                    // top: -10px;
                    height: 7.7rem;
                    font-size: 2.2rem;
                    font-weight: 700;

                    @media (max-width:1199px) {
                        // right: -8.5rem;
                    }

                    @media (max-width:1023px) {
                        // right: -0.5rem;
                    }

                    @media (max-width: 767px) {
                        margin-top: 1.5rem;
                        position: static;
                        font-size: 1.6rem;
                        height: 5rem;
                        width: 17rem;
                        padding: 0;
                    }
                }
            }
        }
    }

    .shiba_loin {
        position: absolute;
        top: -270px;
        left: 0px;
        z-index: -1;
        object-fit: cover;

        img {
            height: 117.4rem;
            width: 122.2rem;
            object-fit: contain;
        }
    }
}