@import "../../../../Assets/theme/_var.scss";

.header_nav {
    display: flex;
    align-items: center;

    li {
        margin-left: 0.9rem;

        a,
        a:not([href]) {
            font-size: 1.4rem;
            cursor: pointer;
            font-weight: 600;
            line-height: 1.7rem;
            color: $white;
            padding-inline: 2.5rem;
            height: 3.7rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            z-index: 0;

            @media (max-width: 1199px) {
                padding-inline: 1.6rem;
                font-size: 1.3rem;
            }

            &::before {
                height: 0.1rem;
                background-color: $orange2;
                position: absolute;
                width: 1rem;
                left: 0;
                transform: rotate(45deg);
                bottom: 0.35rem;
            }

            &::after {
                height: 0.1rem;
                width: calc(100% - 0.8rem);
                background-color: $orange2;
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &:hover {
                color: $white;
            }

            &[data-disabled="true"] {
                pointer-events: none;
                opacity: 0.65;
            }
        }

        :global {

            a.active,
            a:not([href]).active {
                background: linear-gradient(180deg, rgba($white, 0.15) 10.53%, rgba(0, 0, 0, 0) 100%);
                // background-color: $black;
                color: #FF7C49;

                &::after,
                &::before {
                    content: "";
                }
            }
        }
    }

    @media (max-width: 991px) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        max-width: 24rem;
        height: 100vh;
        overflow: auto;
        display: block;
        z-index: 1050;
        background-color: $basecolor;
        transition: 200ms ease-in-out;
        border-right: 1px solid rgba($orange2, 0.3);
        box-shadow: 0 1rem 1rem 0.4rem rgba($orange2, 0.3);
        transform: translateX(-100%);

        li {
            display: block;
            margin-left: 0;

            a,
            a:not([href]) {
                height: 6.2rem;
                padding: 0 2rem 0 4rem;
                width: 100%;
                justify-content: flex-start;
                text-align: left;
            }
        }

        &.active {
            transform: translateX(0);
        }
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba($basecolor, 0.4);
    backdrop-filter: blur(0.6rem);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1049;
    visibility: hidden;
    opacity: 0;
    transition: 200ms ease-in-out;

    &.active {
        visibility: visible;
        opacity: 1;
    }
}