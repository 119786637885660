@import "../../../Assets/theme/_var.scss";

.heading_text {
    text-align: center;

    h2 {
        font-size: 3.2rem;
        position: relative;
        font-family: $secondaryfont;
        font-weight: 400;
        line-height: 3.2rem;
        padding-bottom: 1rem;

        &::after {
            position: absolute;
            content: "";
            width: 11.9rem;
            height: 2px;
            background-color: $orangedark;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }


        @media (max-width:1439px) {
            font-size: 2.8rem;
        }

        @media (max-width: 1199px) {
            font-size: 2.6rem;
        }

        @media (max-width:575px) {
            font-size: 2.2rem;
        }
    }

    p {
        font-size: 1.8rem;
        margin-top: 3rem;
        font-weight: 400;
        line-height: 2.194rem;

        @media (max-width: 1199px) {
            font-size: 1.6rem;
        }

        @media (max-width:575px) {
            font-size: 1.4rem;
        }
    }
}