@import "../../../Assets/theme/_var.scss";

.auth_layout {
    min-height: 100vh;
    padding-top: 13.6rem;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(../../../Assets/Images/auth-bg-2.png);
    display: flex;
    flex-direction: column;

    @media (max-width: 1439px) {
        padding-top: 12.7rem;
    }

    @media (max-width: 1199px) {
        padding-top: 10.5rem;
    }

    @media (max-width: 991px) {
        padding-top: 8.7rem;
    }

    @media (max-width: 767px) {
        padding-top: 7.6rem;
    }

    &.no_header {
        background-image: url(../../../Assets/Images/auth-bg.png);
        padding-top: 0;
    }
}