@import "../../../Assets/theme/_var.scss";

.common_header {
    text-align: center;

    h2 {
        color: $white;
        font-family: $secondaryfont;
        font-size: 3.8rem;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;

        @media (max-width: 1439px) {
            font-size: 3.2rem;
        }

        @media (max-width:767px) {
            font-size: 2.8rem;
        }

        @media (max-width:575px) {
            font-size: 1.8rem;
        }

        &::after {
            position: absolute;
            content: "";
            width: 11.9rem;
            height: 2px;
            background-color: $orangedark;
            bottom: -1rem;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    p {
        font-size: 1.8rem;
        font-weight: 400;
        margin-top: 2.1rem;
        color: $white;

        @media (max-width: 1199px) {
            font-size: 1.6rem;
        }

        @media (max-width: 767px) {
            font-size: 1.4rem;
            margin-top: 1.6rem;
        }
    }
}