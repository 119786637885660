@import "../../../../Assets/theme/_var.scss";

.tokens {
    padding: 10rem 0 5rem;
    @media (max-width: 991px) {
        // padding-bottom: 16rem;
        padding: 0rem 0 4rem;

    }
    :global {
        .container {
            position: relative;
        }
    }

    .box {
        fill: rgba(45, 37, 33, 0.20);
        stroke-width: 1px;
        stroke: $bordercolor;
        box-shadow: 0px 4px 11px 0px rgba(255, 252, 251, 0.29) inset;
        backdrop-filter: blur(2.2rem);
        border: 1px solid $bordercolor;
        border-radius: 2rem;
        padding: 5.5rem 0 4rem;
        position: relative;
        z-index: 3;

    }

    .pumpkin {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        transform: translate(-40%, -40%);
        width: 17rem;
        @media (max-width: 767px) {
            display: none;
        }
    }

    .heading {
        margin-bottom: 4rem;
    }

    .token_img {
        width: 100%;
        max-width: 120rem;
        margin-inline: auto;
        display: block;
    }
}