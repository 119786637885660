@import "../../../../Assets/theme/_var.scss";

.toggler {
    z-index: 1050;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    margin-left: 0.8rem;
    position: relative;

    span {
        display: block;
        border-radius: 0.8rem;
        margin-bottom: 0.68rem;
        width: 2.8rem;
        height: 0.3rem;
        background-color: $orange2;
        transition: 200ms ease-in-out;
        transform-origin: left;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.active {
        span {
            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }

            &:nth-child(2) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}