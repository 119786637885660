@import "../../../../Assets/theme/_var.scss";

.ecosystem {
    position: relative;
    overflow-x: hidden;
    padding-bottom: 10rem;
    @media (max-width: 991px) {
        padding-bottom: 7rem;

    }

    .pumpkin {
        position: absolute;
        right: -7rem;
        bottom: 0;
    }

    @media (max-width: 1439px) {
        // padding-bottom: 20rem;

        .pumpkin {
            max-width: 20rem;
        }
    }

    @media (max-width: 1199px) {
        // padding-bottom: 16rem;

        .pumpkin {
            max-width: 14rem;
        }
    }
    @media (max-width: 991px) {
        // padding-bottom: 16rem;

        .pumpkin {
           display: none;
        }
    }

    &_header {
        p {
            margin-top: 3.6rem;
            max-width: 91rem;
            margin-inline: auto;
        }
    }

    &_video {
        margin: 06rem auto 0;
        overflow: hidden;
        width: 100%;
        max-width: 134.6rem;
        border-radius: 2.3rem;
        box-shadow: 0px 10px 24px 0px rgba($black, 0.20);
        position: relative;
        // background: $darkBlue;

        iframe {
            width: 100%;
            min-height: 50rem;

            @media (max-width: 991px) {
                min-height: 40rem;
            }

            @media (max-width: 767px) {
                min-height: 35rem;
            }
        }

        .play_btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 15.9rem;
            height: 15.9rem;
            border-radius: 50%;
            border: 1rem solid $white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $basecolor2;

            >span {
                transform: translateX(0.4rem);
            }

            @media (max-width: 1439px) {
                width: 10%;
                height: auto;
                aspect-ratio: 1;
                border-width: 0.4rem;

                svg {
                    transform: scale(0.5);
                }

                span {
                    svg {
                        transform: scale(1) translateX(-0.4rem);
                    }
                }
            }

            @media (max-width: 991px) {
                width: 6rem;
                height: 6rem;
            }

            @media (max-width: 767px) {
                svg {
                    transform: scale(40%);
                }
            }

            @media (max-width: 479px) {
                width: 4.4rem;
                border-width: 0.2rem;
                height: 4.4rem;

                svg {
                    transform: scale(0.6) translateX(-0.3rem);
                    width: 100%;
                }
            }
        }
    }
}