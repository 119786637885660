@import "../../../../Assets/theme/_var.scss";

.spinner {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba($basecolor, 0.0);
    backdrop-filter: blur(1rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1060;

    :global {
        .spinner-border {
            width: 4rem;
            height: 4rem;
            border-width: 0.5rem;
            border-color: $orangedark;
            border-right-color: transparent;
        }
    }
}