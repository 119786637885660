@import "../../../Assets/theme/_var.scss";

.profile_team {
    text-align: center;
    position: relative;
    max-width: 320px;
    margin: 0 auto;

    .advise_img {
        // text-align: center;
        // position: relative;
        // background-image: url('../../../Assets/Images/hud.png');
        // background-repeat: no-repeat;
        // background-size: 100%;
        // height: 470px;
        // padding: 4.5rem;
        position: relative;
        border: 1px solid orange;
        border-image: url(../../../Assets/Images/hud.png);
        border-image-slice: 80;
        border-image-width: 70px 72px 92px 78px;
        border-image-outset: 0;
        padding: 36px;

        // .advise_bg {
        //     border-image: url('../../../Assets/Images/hud.png');
        //     // border: 10px solid transparent;
        //     padding: 15px;

        // }
        // img {
        //     width: 100%;
        // }
    }

    .linked_in {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        background: $white;
        border-radius: 5rem;
        width: 5.2rem;
        height: 5.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;

        img,
        svg {
            width: 4.4rem;
            border-radius: 50%;
            height: 4.4rem;
            background: #000;
            padding: 7px;
        }
    }

    .profile_content {
        margin-top: 4rem;

        h6 {
            font-size: 2.2rem;
            font-weight: 400;
            color: $white;
            margin-top: 1.6rem;
            font-family: $secondaryfont;
        }

        p {
            font-size: 1.6rem;
            font-weight: 500;
            color: rgba($white, 0.8);
            margin-top: 0.8rem;
            margin-bottom: 0;

            @media (max-width: 767px) {
                font-size: 1.4rem;
            }
        }

        .ambassadors_info {
            width: 100%;
            max-width: 268px;
            margin: 1.6rem auto 0;
            color: rgba($white, 0.5);
        }
    }
}