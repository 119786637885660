@import "../../../../Assets/theme/_var.scss";

.roadmap {
    background-image: url(../../../../Assets/Images/roadmap-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 16.8rem 0 21.4rem;

    @media (max-width: 1439px) {
        padding: 10rem 0;
    }

    @media (max-width: 1365px) {
        padding: 8rem 0;
    }

    .roadmap_heading {
        margin-bottom: 4rem;
    }

    @media (min-width: 1440px) {
        .roadmap_heading {
            p {
                margin-top: 3.6rem;
            }
        }
    }

    &_inner {
        position: relative;
        mix-blend-mode: normal;

        :global {
            .slick-slider {
                .slick-list {
                    .slick-track {
                        position: relative;

                        &::before {
                            content: "";
                            height: 10.8rem;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            top: 6rem;
                            background-image: url(../../../../Assets/Images/roadmap-line.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;

                            @media (max-width: 1439px) {
                                // top: 3.8rem;
                                background-image: url(../../../../Assets/Images/straight-line.svg);
                                top: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        .roadmap_line {
            position: absolute;
            top: 7.7rem;
            left: 0;

            @media (max-width: 1440px) {
                top: 3.7rem;
            }
        }

        .roadmap_container {
            @media (min-width: 1440px) {
                div {
                    &.Q22023 {
                        margin-top: 3rem;
                    }

                    &.Q32023 {
                        margin-top: 0rem;
                    }

                    &.Q42023 {
                        margin-top: 3rem;
                    }

                    &.Q12024 {
                        margin-top: 8.6rem;
                    }

                    &.Q22024 {
                        margin-top: 0rem;
                    }

                    &.Q32024 {
                        margin-top: 1rem;
                    }

                    &.Q42024 {
                        margin-top: 4rem;
                    }

                    &.Q12025 {
                        margin-top: 0rem;
                    }
                }
            }
        }
    }
}