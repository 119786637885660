@import "../../../../Assets/theme/_var.scss";


.TeamSec {
    background-color: $basecolor;
    position: relative;
    padding: 15.5rem 0 10rem;

    @media (max-width:1679px) {
        padding: 4rem 0;
    }

    &_box {
        margin-top: 6.4rem;

        :global {
            .row {
                row-gap: 7rem;

                @media (max-width:991px) {
                    row-gap: 3rem;
                }
            }
        }

        &_row {
            text-align: center;
            position: relative;

            .advise_img {
                text-align: center;
                position: relative;
                background-image: url(../../../../Assets/Images/hud.png);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 470px;
                padding: 4.5rem;

                // .advise_bg {
                //     border-image: url('../../../Assets/Images/hud.png');
                //     // border: 10px solid transparent;
                //     padding: 15px;

                // }
                img {
                    width: 100%;
                }
            }

            .linked_In {
                position: absolute;
                bottom: 23%;
                left: 50%;
                transform: translate(-50%, 8%);
                background: $white;
                border-radius: 5rem;
                width: 5.2rem;
                height: 5.2rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 4.4rem;
                    height: 4.4rem;
                }
            }

            img {
                width: 100%;
            }

            h6 {
                font-size: 2.2rem;
                font-weight: 400;
                color: $white;
                margin-top: 1.6rem;
                font-family: bruce_foreverregular;
            }

            p {
                font-size: 1.6rem;
                font-weight: 500;
                color: rgba($white, 0.8);
                margin-top: 0.8rem;
                margin-bottom: 0;
            }

            .ambassadors_info {
                width: 100%;
                max-width: 268px;
                margin: 1.6rem auto 0;
                color: rgba($white, 0.5);
            }
        }
    }

    .team_position {
        position: absolute;
        top: 0;
        right: 0;
    }
}