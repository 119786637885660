@import "../../../Assets/theme/_var.scss";

.card {
    .card_inner {
        // background-color: $baseDark;
        padding: 3rem 4rem 3.4rem;
        position: relative;
        overflow: hidden;
        border-radius: 2rem;
        // border: 1px solid $black;
        border: 1px solid transparent;
        background: linear-gradient(#140C0B, #140C0B) padding-box, linear-gradient(42.64deg, #863E1F 8%, rgba(0, 0, 0, 0) 53.04%) border-box;
        z-index: 1;

        @media (max-width: 1439px) {
            padding: 2.4rem 3rem;
        }

        @media (max-width: 1199px) {
            padding: 1.8rem 2.6rem;
        }

        @media (max-width: 575px) {
            padding: 1.8rem 1.6rem;
        }

        &::after,
        &::before {
            content: "";
            width: 21.8rem;
            height: 18.9rem;
            border-radius: 50%;
            right: -11.7rem;
            z-index: -1;
            position: absolute;
            filter: blur(85px);
            pointer-events: none;
            opacity: 0.5;
        }

        &::after {
            top: -13.2rem;
            background-color: $orangedark;
        }

        &::before {
            top: -2rem;
            background-color: $yellow;
        }

        .card_header {
            h3 {
                font-family: $secondaryfont;
                font-size: 2rem;
                font-weight: 400;
                line-height: 2.4rem;
                text-align: center;
                position: relative;
                padding: 0 4rem 2.4rem;

                @media (max-width: 1439px) {
                    font-size: 1.8rem;
                    padding: 0 2.4rem 2rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.6rem;
                    padding: 0 1.8rem 1.6rem;
                }

                &::after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    max-width: 39.8rem;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $radialGradient1;
                }
            }
        }
    }
}