@import './Assets/theme/_var.scss';

html {
  font-size: 62.5%;
}

body {
  min-width: 32rem;
  font-family: $basefont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $basecolor;
  color: $white;
}

// **** Container *****
.container {
  @media (max-width: 991px) {
    max-width: 100%;
  }

  @media (min-width: 1680px) {
    max-width: 161.7rem;
    padding-inline: 1.2rem;
  }
}

a {
  z-index: 9999 + 1;
  position: relative;
  text-decoration: none;
}

span,
a {
  display: inline-block;
}

p,
h1,
h5,
h6,
h2,
h3,
h4 {
  margin-bottom: 0;
}

img,
svg,
video {
  max-width: 100%;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
  padding: 0;
  margin: 0;
}

// **** slider css *** *
.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// **** button  **** 
button {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
}


// *** modal ****

.modal-backdrop {
  &.fade {
    opacity: 0;
  }

  &.show {
    background-color: rgba(#0D0504, 0.0);
    opacity: 1;
    backdrop-filter: blur(1rem);
  }
}

.table {
  --bs-table-bg: transparent;
}

.commondrop {
  .dropdown-toggle {
    background: transparent;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: 600;
    line-height: 1.7rem;
    color: #fff;
    padding-inline: 2.5rem;
    height: 3.7rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    @media (max-width:1399px) {
      padding-inline: 1rem;
    }

    @media (max-width:991px) {
      height: 6.2rem;
      padding: 0 2rem 0 4rem;
      width: 100%;
      justify-content: flex-start;
      text-align: left;
    }

    &:active,
    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }

    &::after {
      border: none;
      display: none;
    }

    svg {
      margin-left: 1rem;
      transition: 0.3s ease-in-out;

      path {
        stroke-width: 0.1rem;
      }
    }

    &.show {
      background: linear-gradient(180deg, rgba($white, 0.15) 10.53%, rgba(0, 0, 0, 0) 100%);
      color: #FF7C49;

      &::before {
        content: '';
        height: 0.1rem;
        background-color: $orange2;
        position: absolute;
        width: 1rem;
        left: 0;
        transform: rotate(45deg);
        bottom: 0.35rem;
      }

      &::after {
        content: '';
        border: none;
        height: 0.1rem;
        width: calc(100% - 0.8rem);
        background-color: $orange2;
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
      }

      svg {
        transform: rotate(180deg);
        transition: 0.3s ease-in-out;

        path {
          fill: #FF7C49;
          stroke: #FF7C49;
        }
      }
    }
  }

  .dropdown-menu.show {
    background: #292A31;
    transform: translate(0px, 43px) !important;
    padding: 0;

    .dropdown-item {
      color: #FFF;
      font-family: 'Montserrat';
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;
      text-transform: uppercase;
      border-bottom: 1px solid #414141;
      padding: 1.6rem;
      height: 4.9rem;
      min-width: 15rem;
      align-items: center;
      justify-content: flex-start;

      &::after,
      &::before {
        display: none;
      }

      &:last-child {
        border: none;
      }

      &:hover,
      &:focus {
        background: #FF7C49;
      }
    }
  }
}