@import "../../../Assets/theme/_var.scss";

.FooterSec {
    // background-image: url('../../../Assets/Images/footer-bg.jpg');
    background-color: rgba($black , 20%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
    padding: 4rem 0;
    z-index: 0;
    position: relative;
    margin-top: auto;

    @media (max-width:1679px) {
        padding: 3rem 4rem 1rem;
    }

    @media (max-width:767px) {
        padding-bottom: 2rem;
    }

    :global {
        .container {
            max-width: 1600px;
            width: 100%;
        }
    }

    &_box {
        &_links {
            display: flex;
            align-items: center;

            @media (max-width:991px) {
                display: block;
            }

            .footer_img {
                padding-right: 10.8rem;
                display: flex;
                align-items: center;

                @media (max-width:1679px) {
                    padding-right: 5rem;
                }

                @media (max-width:1199px) {
                    justify-content: center;
                    padding-right: 0;
                }

                .footer_logo {
                    margin-right: 3.7rem;
                    flex-shrink: 0;

                    @media (max-width:1679px) {
                        margin-right: 2rem;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        width: 5.8rem;
                        height: 5.8rem;

                        @media (max-width:1199px) {
                            width: 3.5rem;
                            height: 3.5rem;
                        }
                    }
                }
            }

            .footer_links {
                width: 100%;

                @media (max-width:991px) {
                    margin-top: 2rem;
                }

                .unstyle_list {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width:767px) {
                        justify-content: center;
                    }

                    @media (max-width:575px) {
                        text-align: center;
                        display: block;
                    }

                    li {

                        // padding-right: 11rem;
                        @media (max-width:1679px) {
                            padding-right: 1rem;
                        }

                        // @media (max-width:991px) {
                        //     padding-right: 2rem;
                        // }

                        @media (max-width:575px) {
                            padding-right: 0;
                            padding: 1rem 0;
                        }

                        a {
                            font-size: 1.8rem;
                            font-weight: 600;
                            color: $reddark;

                            @media (max-width:1679px) {
                                font-size: 1.6rem;
                            }

                            @media (max-width:1199px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }

        &_copyright {
            text-align: left;
            margin-top: 3.5rem;
            display: flex;
            align-items: center;

            @media (max-width:1199px) {
                text-align: center;
            }

            @media (max-width: 767px) {
                display: block;
            }

            span {
                color: $white;
                font-size: 1.8rem;
                font-weight: 400;

                @media (max-width: 1199px) {
                    font-size: 1.6rem;
                }

                @media (max-width:575px) {
                    font-size: 1.4rem;
                }
            }

            h5 {
                font-size: 1.6rem;
                font-weight: 600;
                margin-right: 1rem;

                @media (max-width: 767px) {
                    font-size: 1.4rem;
                    margin-right: 0.8rem;
                }
            }

            img {
                max-width: 14rem;
                display: block;
            }

            a {
                font-size: 2rem;
                color: $white;
                display: block;
                font-weight: 500;
                line-height: 1.4;
                text-decoration: underline;
                margin-top: 1rem;

                @media (max-width: 1439px) {
                    font-size: 1.8rem;
                }

                @media (max-width: 1199px) {
                    font-size: 1.6rem;
                }

                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }
            }
        }

        &_socialLinks {

            // margin-top: 1.1rem;
            margin-inline: auto;
            width: 100%;

            @media (min-width: 1200px) {
                width: 87.5%;
                margin-left: auto;
            }

            // max-width: 100%;

            // @media (max-width:767px) {
            //     margin-top: 2rem;
            // }

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                // justify-content: space-between;
                // padding-left: 10rem;
                margin: 0 0 -3rem auto;
                justify-content: end;
                // @media (max-width:1679px) {
                //     padding-left: 2rem;
                // }

                @media (max-width:1199px) {
                    margin: 3rem auto -3rem -3rem;
                    justify-content: center;
                }

                // flex-wrap: wrap;

                li {
                    // padding-right: 4.5rem;
                    flex-shrink: 0;
                    width: 3.6rem;
                    height: 3.6rem;
                    margin: 0 0 0rem 3rem;
                    // @media (max-width:1679px) {
                    //     padding-right: 2rem;
                    // }
                    a {
                        svg {
                            width: 3.6rem;
                            height: 3.6rem;

                            @media (max-width: 1439px) {
                                width: 3rem;
                                height: 3rem;
                            }

                            @media (max-width:991px) {
                                width: 2.6rem;
                                height: 2.6rem;
                            }
                        }
                    }
                }
            }
        }

        .list_links {
            margin-top: 3.1rem;
        }
    }
}