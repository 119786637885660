@import "../../../../../Assets/theme/_var.scss";

.roadmap_box {
    padding-inline: 1.2rem;

    .roadmap_box_title {
        width: 13.5rem;
        height: 15.8rem;
        background-image: url(../../../../../Assets/Images/roadmapbg.svg);
        background-size: cover;
        background-position: bottom center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: relative;
        margin-bottom: 5.4rem;

        h3 {
            color: $white;
            text-align: center;
            font-family: $secondaryfont;
            font-size: 3.2rem;
            font-weight: 400;
            text-transform: uppercase;
        }

        span {
            color: $white;
            font-family: $secondaryfont;
            font-size: 1.8rem;
            font-weight: 400;
            text-transform: uppercase;
        }

        &::after {
            content: "";
            height: 5.4rem;
            position: absolute;
            top: 100%;
            width: 0.2rem;
            background: linear-gradient(179.75deg, #D85826 4.88%, rgba(0, 0, 0, 0) 105.39%);
        }

        @media (max-width: 1439px) {
            width: 10rem;
            height: 12rem;

            h3 {
                font-size: 2.6rem;
            }

            span {
                font-size: 1.6rem;
            }
        }

        @media (max-width: 1199px) {
            h3 {
                font-size: 2.2rem;
            }

            span {
                font-size: 1.4rem;
            }
        }

        @media (max-width: 991px) {
            h3 {
                font-size: 1.8rem;
            }
        }
    }

    ul {
        max-width: 24.4rem;
        // margin-inline: auto;
        margin-left: 5rem;
        @media (max-width: 991px) {
                   margin-inline: auto;

        }
        li {
            margin-bottom: 1.5rem;
            display: flex;
            align-items: flex-start;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.5rem;
            color: rgba($white, .7);

            @media (max-width: 1199px) {
                font-size: 1.4rem;
                margin-bottom: 2rem;
                line-height: 2.6rem;
            }

            @media (max-width: 991px) {
                margin-bottom: 1.4rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                transform: translateY(0.8rem);
                margin-right: 0.8rem;
                flex-shrink: 0;
            }
        }
    }
}