@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
    font-family: 'bruce_foreverregular';
    src: url('../fonts/bruce_forever-webfont.woff2') format('woff2'),
        url('../fonts/bruce_forever-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$basefont: "Montserrat";
$secondaryfont: bruce_foreverregular;
$poppins: "Poppins", sans-serif;
$basecolor: #0D0504;
$basecolor2: #021E1C;
$basecolor3: #363333;
$white: #fff;
$black: black;
$darkBlue: #191F2F;
$baseDark: #140C0B;
$basecolor4: #180E0C;
$orangedark: #FE6A30;
$reddark: #F17130;
$orange2: #FF7C49;
$orange4: #FDCA18;
$orange3: #FF6A30;
$orangedark2: #2C1F18;
$yellow: #FFE45C;
$gradient1: linear-gradient(270deg, #322220 0%, rgba(27, 16, 15, 0.00) 100%);
$bordercolor: #6C554D;
$bordercolor2: #514138;
$gray: #AFAFAF;
$radialGradient1: radial-gradient(50% 7920198.86% at 50% 0%, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%); // CommonCard.module.scss