@import "../../../../Assets/theme/_var.scss";

.lore_section {
    text-align: center;
    padding-bottom: 14.9rem;

    @media (max-width: 1199px) {
        padding-bottom: 10rem;
    }

    @media (max-width: 991px) {
        padding-bottom: 6rem;
    }

    .heading {
        margin-bottom: 6rem;

        @media (max-width: 991px) {
            margin-bottom: 4rem;
        }
    }

    p {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 3rem;

        @media (max-width: 1199px) {
            font-size: 1.6rem;
        }

        @media (max-width: 767px) {
            font-size: 1.4rem;
        }
    }

    h3 {
        font-size: 2.4rem;
        line-height: 3rem;
        font-weight: 600;
        margin: 4rem 0;

        @media (max-width: 1439px) {
            font-size: 2.2rem;
        }

        @media (max-width: 1279px) {
            font-size: 2rem;
            margin: 3rem 0;
        }

        @media (max-width: 1199px) {
            font-size: 1.8rem;
            margin: 2rem 0;
        }

        @media (max-width: 991px) {
            font-size: 1.6rem;
            margin: 1rem 0;
        }
    }
}

.game_section {
    &.antiheroes {
        padding: 15.5rem 0 13.1rem;

        @media (max-width: 1439px) {
            padding: 10rem 0;
        }

        @media (max-width: 1199px) {
            padding: 6rem;
        }

        @media (max-width: 991px) {
            padding: 4rem 0;
        }
    }

    .heading {
        margin-bottom: 5.6rem;
    }

    :global {
        .container {
            max-width: 130rem;

            .row {
                margin: -2rem -1rem;

                >div {
                    padding: 2rem 1rem;
                }
            }
        }
    }

    .game_box {
        img {
            width: 100%;
        }
        @media (max-width: 767px) {
            text-align: center;
        }
        h3 {
            margin: 4.1rem 0 4rem;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 3rem;
            font-family: $secondaryfont;
            @media (max-width: 1439px) {
                font-size: 2.2rem;
            }

            @media (max-width: 1279px) {
                font-size: 2rem;
                margin: 3rem 0;
            }

            @media (max-width: 1199px) {
                font-size: 1.8rem;
                margin: 2rem 0;
            }

            @media (max-width: 991px) {
                font-size: 1.6rem;
                margin: 1rem 0;
            }
        }

        p {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 3rem;

            @media (max-width: 1199px) {
                font-size: 1.6rem;
            }

            @media (max-width: 767px) {
                font-size: 1.4rem;
            }
        }
    }

    .more_text {
        margin-top: 6.9rem;
        color: $orangedark;
        text-align: center;
        display: block;
        width: fit-content;
        margin-inline: auto;
        font-size: 2.4rem;
        font-weight: 400;
        line-height: 3rem;
        z-index: 1;
        @media (max-width: 1439px) {
            font-size: 2.2rem;
        }

        @media (max-width: 1279px) {
            font-size: 2rem;
            margin-top: 3rem;
        }

        @media (max-width: 1199px) {
            font-size: 1.8rem;
        }

        @media (max-width: 991px) {
            font-size: 1.6rem;
        }
    }
}