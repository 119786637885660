@import "../../../../Assets/theme/_var.scss";

.Backers_sec {
    // background-color: $basecolor;
    position: relative;
    padding: 8.5rem 0;

    @media (max-width:991px) {
        padding: 4rem 0;
    }

    &_box {
        fill: rgba(45, 37, 33, 0.20);
        stroke-width: 1px;
        stroke: $bordercolor;
        box-shadow: 0px 4px 11px 0px rgba(255, 252, 251, 0.29) inset;
        backdrop-filter: blur(22px);
        border: 1px solid $bordercolor;
        border-radius: 2rem;
        padding: 5.5rem;
        position: relative;
        z-index: 1;

        @media (max-width:1679px) {
            padding: 3rem 2rem;
        }

        // h2 {
        //     font-size: 4.2rem;
        //     font-weight: 400;
        //     color: $white;
        //     position: relative;

        //     @media (max-width:767px) {
        //         font-size: 2.8rem;
        //     }

        //     &::after {
        //         position: absolute;
        //         content: "";
        //         width: 100px;
        //         height: 2px;
        //         background-color: $orangedark;
        //         bottom: 0;
        //         left: 50%;
        //         transform: translate(-50%, 0);
        //         ;
        //     }
        // }

        &_row {
            margin-top: 4rem;

            @media (max-width:1679px) {
                margin-top: 3.5rem;
            }

            // :global {
            //     .row {
            //         row-gap: 4rem;

            //         >div {
            //             flex-grow: 1;

            //             @media (max-width: 991px) {
            //                 flex-grow: 0;
            //             }
            //         }
            //     }
            // }
            .buy_col {
                flex-grow: 1;
            }

            .backers_img {
                text-align: center;
                padding: 0 1rem;

                .partner_img {
                    object-fit: contain;
                    max-height: 8rem;
                    width: 100%;
                }

                .back_img {
                    max-width: 18rem;
                }

                // img {
                //     width: 160px;
                //     height: 42px;
                // }
            }

            // ul {
            //     display: flex;
            //     align-items: center;
            //     justify-content: space-between;
            //     margin-top: 6.8rem;
            //     padding: 0;
            //     flex-wrap: wrap;

            //     li {
            //         list-style: none;
            //         padding: 1.5rem 2.5rem;
            //         &:first-child{
            //             padding-left: 0;
            //         }
            //         &:last-child{
            //             padding-right: 0;
            //         }
            //     }
            // }
        }
    }

    &_pumpkinImg {
        position: absolute;
        top: 3.7%;
        left: 1.9%;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .partners_pumpkin {
        position: absolute;
        top: 1%;
        right: 2.4%;
        left: unset;
        rotate: 15deg;
    }

    :global {
        .slick-slide {
            img {
                display: inline;
            }
        }

        .slick-list {
            padding: 2rem 0;
        }
    }
}